.con-card{
    margin-top: 3rem;
    margin-bottom: 2rem;
    border: none;
}
.name,.email,.subject,.msg{
    font-weight: 500;
   
}
.con-btn{
    margin-bottom: 2rem;
    display: flex;
    border-radius: 8px;
    font-weight: 500;
    
}
.form-label{
    float: left;
}

.forgot{
    margin-top: 3rem;
    margin-bottom: 2rem;
    border: none;
}