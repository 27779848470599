.c-item{
  height: 480px;
}
.c-img{
  height: 100%;
  object-fit: cover;
  filter: brightness(0.6);

}
.servicetext{
  color: rgb(255,145,77);
  color: linear-gradient(90deg, rgba(255,145,77,1) 1%, rgba(241,99,12,1) 37%, rgba(255,133,77,1) 86%, rgba(255,133,77,1) 100%);
}
