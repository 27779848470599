
.maindiv{
margin-top: 70px;
}
.main-title{
    font-weight: bolder;
    font-size:280%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-align: center;
    justify-content: center;    
 }
 .desc{
     font-weight:525;
     text-align: center;
     color:#393635
 }
 .sidetxtc{
     transform: translate(-50,-50);
     top:40%;
     left: 3%;
     position: absolute;
     line-height: 1.5;
     font-size: 1.5vw;
     background-color: #393635;
     box-shadow: 2px 2px 2px 2px rgb(94, 91, 91);
     color:#ffffff;
     border-top-left-radius: 50px;
    
 }
 .sidetxtc1{
     transform: translate(-50,-50);
     top:55%;
     right: 3%;
     position: absolute;
     line-height: 1.5;
     font-size: 1.5vw;
     background-color: #393635;
     color: #ffffff;
     box-shadow: 2px 2px 2px 2px rgb(94, 91, 91);
     border-bottom-right-radius: 50px;
   
 }
 .imgcard1{
    width: 35%;
    height: 70%;
 }
 @media screen and (max-width: 450px) {
    .main-title{
        font-size: larger;
        margin-top: 25px;
    }
    .desc{
        font-size: xx-small;
    }
    .imgcard1{
        width: 90%;
        height: 90%;
    }
     .sidetxtc{
         top: 40%;
        left: 10%;
         border-top-left-radius: 25px;
     }
     .sidetxtc1{
         top:40%;
         right: 10%;
         border-bottom-right-radius: 25px;
     }
 }
 .stxt{
     margin: 1.5vw;
     text-align: center;
     word-wrap: break-word;
     color: #ffffff;
     font-weight: bold;
     
 }
 hr{
     margin-left: 1%;
     box-shadow: 1px;
 }
 .overview{
     text-align: left;
     font-weight: bold;  
 }
 .otext{
     font-weight:525;
     font-size: large;
     font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     text-align: justify;
     word-wrap:break-word;
     line-height:1.5;
 }
 .imgcard1,.bigcard1{
     border:none;
 }
 .bigcard1{
    text-align: center;
 }
 .android-text{
     font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     text-align: justify;   
     word-wrap: break-word;
     line-height:1.5;
     font-size: large;
    
 }
 h2{
     margin-top: 15vw;
     text-align: center;
     font-weight: bold;
     color: rgb(25, 25, 144);
 }
 .head3{
     text-align: center;
     font-weight: bold;
     font-family:'Times New Roman', Times, serif;
     color: rgb(25, 25, 144);
 
 }
 
 .andimg{
     margin-right: 3%;
 }
 .andlogoimg{
     float: right;
     box-shadow: 1px 2px 2px 2px rgba(20,20,20,0.4);
     margin-right: 2%;
 }
 .ioslogoimg{
     float: left;
     box-shadow: 1px 2px 2px 2px rgba(20,20,20,0.4);
     
     margin-left: 2%;
 }
 .andlogoimg:hover,.ioslogoimg:hover,.weblogo:hover{
     box-shadow: 1px 2px 3px 4px rgba(20,20,20,0.4);
 }
 .weblogo{
     box-shadow: 1px 2px 2px 2px rgba(20,20,20,0.4);
     margin-left: 70%;
 }
 .webmain{
 border: solid 2px;
 border-radius: 20px;
 border-color: rgb(160, 159, 159);
 border: none;
 
 }
 .weblogo1{
     transform: translate(-50,-50);
     position: absolute;
     top:0.2%;
     left: 15%;
     border-radius: 200px;
     border: none;
 }
 .webmain1{
     border-radius: 60px;
 }
 .bottom-card{
    
 }
 .webdevdiv{
   background-color: #ffffff;
    
 }
 .spanh2{
     font-size: large;
 }
 
 @media screen and (max-width: 400px) {
 .main-title{
     font-size: 20px;
 
 }
 .desc{
     font-size: 14px;
 }
 .webmaindiv{
     margin: 3px;
 }
 
 }
 
 .imgcard2{
     border: none;
     margin-left: auto;
     margin-right: auto;
     margin-top: 12%;
 }
 .imgcard1{
     margin-left: auto;
     margin-right: auto;
     margin-top: 12.5%;
 }
 .web-dev-ser{
     font-weight: bold;
     font-family:'Times New Roman', Times, serif;
     color: darkblue;
     font-size: 60px;
     margin: 5vw;
 }
 .websertxt{
    font-size: large;
    font-weight: bolder;
 }
 .websertxt1{
     font-size: large;
     font-weight: bolder;
     color: blue;
     
 }
 /* --------------------ecom-------------------------------------- */
 @keyframes example {
     0%   {left:-30px; }
     25%  {left:325px; }
     
   }
@keyframes example1 {
    0%   {left:-30px;}
    25%  {left:20%; }
    
  }
  @keyframes example2 {
    0%   {left:-30px; }
    25%  {left:325px; }
    
  }
 
  
 .main-title-ec-c{
     transform: translate(-50,-50);
   
     position: absolute;
     text-align: center;
    
     color: rgb(123, 12, 227);
     border: none;
     animation-name: example;
     animation-duration: 10s;
 }
 @media screen and (max-width: 400px) {
     .main-title{
         font-size: 12px;
         margin-bottom: 2px;
     
     }
     .desc{
         font-size: 8px;
     }
     .main-title-ec-c{
         top: 22%;
         width: auto;
         padding: 3px;
         justify-content: center;
         animation-name: example1;
         animation-duration: 5s;
        
     }
     .imgcard1{
        margin-top: 20%;
     }
     .imgcard2{
        margin-top: 13%;
     }
     
    
 }
 
 
 
 .ec1{
     margin-top: 14%;
    
    margin-left: auto;
    margin-right: auto;
 }
 .main-title-ec-c{
     background: transparent;
     display: flex;
     justify-content: center;
     align-items: center;
     
 }
 .main-title{
     color: #ff914d;
     margin-bottom: 3%;
 }
 .ecomh1{
     font-weight: bold;
     font-family:'Times New Roman', Times, serif;
    
     font-size: 4vw;
     animation-name: example1;
     animation-duration: 4s;
     color: #ffffff;
 }
 .div1-ec{
     border-bottom-left-radius: 30px;
 }
 .div2-ec{
     background-color: #FF914D;
     border-top-left-radius: 200px;
     border-top-right-radius: 200px;
     
 
 }
 .otext1{
     font-weight:630;
     font-size: medium;
     font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     text-align: justify;
     word-wrap:break-word;
     line-height:1.5;
     margin: 3vw;
 
     
 }
 
 .ec2{
     
 }
 .ecomh2{
     font-weight: bold;
     font-family:'Times New Roman', Times, serif;
     color: aliceblue;
     font-size: 4vw;
     margin-top: 3vw;
   
 }
 .ecomtxt{
     color: white;
     word-wrap:break-word;
     margin-left: 5vw;
     margin-right: 5vw;
 
 }
 .div3-ec{
     background-color:#393635;
     border-bottom-left-radius: 180px;
     border-bottom-right-radius: 200px;
 }
 .ecard1{
     background: transparent;
     border: dotted;
     border-color: aliceblue;
 }
 .ectxt1{
   margin-top: 2.5vw;
   font-size: larger;
   font-weight: 700;
   color: aliceblue;
   
 }
 .cardrow{
     margin-top: 5vw;
    
     margin-left: 2.5vw;
 }
 .ectxt3{
     margin-top: 2.5vw;
     font-size: medium;
     font-weight: 700;
     color: aliceblue;
 }
 .eslogan{
     font-weight: bold;
     font-family:'Times New Roman', Times, serif;
     color: darkblue;
     font-size: 4vw;
     margin: 5vw;
 }
 .getintouch{
     width: 200px;
     height: 50px;
     border-radius: 200px;
     background-color: rgb(245, 103, 46);
     border-color:  rgb(255, 94, 0);
     font-weight: 700;
     
     font-size:larger;
 }
 .getintouch:hover
 {
    background-color: #393635;
 }
 @media screen and (max-width: 400px) {
         .getintouch{
             width: 80px;
             height: 30px;
            font-size: xx-small;
             font-weight: bold;
         }
 }
 
 /* --------------------digital------------------------------------ */
  .abc{
    margin-top: 15%;
  }
 .main-title-dg-c{
    justify-content: center;
    text-align: center;
     transform: translate(-50,-50);
    
     left: 25%;

     position: absolute;
    
   
     animation-name: example2;
     animation-duration: 10s;
    border-color: white;
 }
 .dgmtext1{
     font-weight:630;
     font-size: medium;
     font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
     text-align: justify;
     word-wrap:break-word;
     line-height:1.5;
     margin: 3vw;
     
 }
     .dgm-dev-ser{
         font-weight: bold;
         font-family:'Times New Roman', Times, serif;
         color: #FF914D;
         font-size: 60px;
         margin: 5vw;
         text-align: center;
     }
     .ser-txt-dgm{
         font-size: xx-large;
         font-weight: 200;
         text-align: center;
         margin-left: 5vw;
         margin-right: 5vw;
         font-family: 'Times New Roman', Times, serif;
      }
      .dgmslogan{
         font-weight: bold;
         font-family:'Times New Roman', Times, serif;
        
         font-size: 30px;
         margin: 5vw;
     }
     .websertxt{
         text-align: center;
      }
      
 @media screen and (max-width: 400px) {
  
         .main-title{
             font-size: 12px;
             margin-bottom: 2px;
         
         }
         .desc{
             font-size: 8px;
         }
         .main-title-dg-c{
            
             width: auto;
            justify-content: center;
             padding: 3px;
             animation-name: example1;
             animation-duration: 10s;
            
         }
        
         .dgm-dev-ser{
            
             font-size: 25px;
             text-align: center;

           
         }
         .dgmtext1{
            
             font-size: xx-small;
            font-weight: 400;
            word-wrap: break-word;
         }
         .ser-txt-dgm{
             font-size: small;
             font-weight: 200;
             word-wrap: break-word;
             line-height: 1.5;
            
          }
          .websertxt{
                 font-size: small;
          }
          .dgmslogan{
             
             font-size: 20px;
             word-wrap: break-word;
           
         }
 } 
 .logotextfirst {
    color:   #FF914D;
   
  }
  .logotextsecond {
    color:   #393635;
   
  }