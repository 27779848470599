
/*
.ht{
    text-align: center;
    color: blue;
    font-family:'Times New Roman', Times, serif;
    font-weight: bold;
    margin-top: 20px;
   
}
h3{
   
    color:rgb(25, 25, 144);
    font-weight: bold;
    
}


.mainCard1{
    border-radius:20px;
    
}
.div1{
    display:flex;
    align-items: center;
    justify-content: center;
}
.c1 .card1{
    background-color:white;
    border-radius: 20px;
    margin-top: 17px;
    text-align: justify;
    word-wrap: break-word;
    
}

.c4 .card4{

    background-color:white;
    border-radius: 20px;
    margin-top: 17px;
    margin-left: 5px; 
    text-align: justify;
    word-wrap: break-word;
}
.c1 .card4{
    background-color:white;
    border-radius: 20px;
    margin-left: 5px;
    text-align: justify;
    word-wrap: break-word;
}

.div2{
   background-color: rgb(254, 245, 232);
   
}

.mainCard1{
    box-shadow: 1px 2px 3px 4px rgba(20,20,20,0.4);
}
.card1,.card2,.card3,.card4{
    box-shadow: 0.5px 1px 1px 1px rgba(121, 120, 116, 0.4);
   
}

.card1:hover{
    background-color:#f5f6ff;
    box-shadow: 3px 4px 6px 7px rgba(20,20,20,0.4);
   
}

.card4:hover{
    background-color:#a86ef3;
    box-shadow: 3px 4px 6px 7px rgba(20,20,20,0.4);
}

.mainCard1:hover{
    box-shadow: 3px 4px 6px 7px rgb(97, 104, 107);
    box-shadow: 3px 4px 6px 7px rgba(20,20,20,0.4);

}
.middle{
    background-color:rgb(39, 39, 125);
}
.middleul{
    color: white;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: larger;
    margin-left: 150px; 
    text-align: left;   
    
}
.middlesp{
    color: white;
    font-size: 35px;
    font-weight: bold;
    font-family:'Times New Roman', Times, serif;
    text-align: center;
}
@keyframes slideIn {
    from{
        transform: translateX(-100%);
    }
    to{
        transform: translateX(0);
    }
}
.headcard{
    margin-top: 50px;
    margin-bottom: 200px;
    z-index: 1;
    animation: slideIn 1.5s ease forwards;
}
h4{
    color:rgb(25, 25, 144);
}
.hctxt{
    font-size:medium;
    font-family: 'Times New Roman', Times, serif;
    box-sizing: border-box;
    height: auto;
    width: auto;
    padding: 15px 15px 15px 15px;
    background-color: white;
    border: solid;
    position: absolute;
    transform: translate(-50%,-50%);
    top:30%;
    left: 65%;
}
.cardimg{
    border-radius: 20px;
}
*/

.service {
    border-bottom:  6px solid #FF914D;
}

.headerc{
    background-color: #FF914D;
    border-bottom-left-radius: 300px;
    border-bottom-right-radius: 300px;
    height: 60px;
    text-align: center;
}

/* Remove underline from all links within breadcrumb */
.custom-breadcrumb .breadcrumb-item a{
    color: #393635; /* Change the color to your desired color */
    font-size: 16px; /* Change the font size */
    font-weight: bold; /* Change the font weight */
    text-decoration: none; /* Remove the underline */
}

.custom-breadcrumb .breadcrumb-item a:hover {
    color: #ffffff;/* Change the color on hover */
    text-decoration: none; /* Remove the underline on hover */
}

.custom-breadcrumb .breadcrumb-item a.active {
    color: #ffffff; /* Change the color of the active item */
    font-style: italic; /* Change the style of the active item */
    text-decoration: none; /* Remove the underline from the active item */
}


  