.ht1{
    color: #FF914D;
       
        margin-top: 100px; 
        font-size: 40px;
        text-align: justify;
        animation: slide-in1 2s ease forwards;
       
    }
    .ht2{
      color: #393536;
        text-align: justify;
        background-color: white;
        animation: slide-in1 2s ease forwards;
    }
   
         
    
    .home{
       
        background-repeat: no-repeat;
    }
    .img1 {
       
        animation: slide-in1 2s ease forwards;
       
    }
    .nm {
        color: #FF914D;
    }
    .P1 {
        margin-top: 20px;
    }
    .id1 {
       border-width: 3px;
       border-bottom: 6px solid #FF914D;
       height: 100%;
       animation: slide-in2 8s ease forwards;
       text-align: justify;
    }
    .id2 {
        border-width: 3px;
        border-bottom: 6px solid #FF914D;
        height: 100%;
        animation: slide-in2 8s ease forwards;
        text-align: justify;
      
     }
     .id3 {
        border-width: 3px;
        border-bottom: 6px solid #FF914D;
        height: 100%;
        animation: slide-in2 8s ease forwards;
        text-align: justify;
      
     }
     .id4 {
        border-width: 3px;
        border-bottom: 6px solid #FF914D;
        height: 100%;
        animation: slide-in2 8s ease forwards;
        text-align: justify;
      
     }
     .id5 {
        border-width: 3px;
        border-bottom: 6px solid #FF914D;
        height: 100%;
        animation: slide-in2 8s ease forwards;
        text-align: justify;
      
     }
     .id6 {
        border-width: 3px;
        border-bottom: 6px solid #FF914D;
        height: 100%;
        animation: slide-in2 8s ease forwards;
        text-align: justify;
    
     }
     .id1:hover {
        background-color: #FF914D;
        color: white;
        font-weight: 600;
     }
     .id2:hover {
        background-color: #FF914D;
        color: white;
        font-weight: 600;
     }
     .id3:hover {
        background-color: #FF914D;
        color: white;
        font-weight: 600;
     }
     .id4:hover {
        background-color: #FF914D;
        color: white;
        font-weight: 600;
     }
     .id5:hover {
        background-color: #FF914D;
        color: white;
        font-weight: 600;
     }
     .id6:hover {
        background-color: #FF914D;
        color: white;
        font-weight: 600;
     }

   .F1 {
      color: #FF914D;
      text-align: center;
   }
   .F2 {
      font-weight: bold;
      font-size: 40px;
   }
   @keyframes slide-in1 {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0%);
      }
    }
    @keyframes slide-in2 {
      0% {
        transform: translateY(-100%);
      }
      100% {
        transform: translateY(0%);
      }
    }
    .CA1:hover,.CA2:hover,.CA3:hover,.CA4:hover,.CA5:hover,.CA6:hover {
      background-color: #FF914D;
      color: #393536;
      }
  