.con-h3{
    
    font-weight: bolder;
    text-align: center;
     margin-top: 1rem;
     background-color: rgb(12, 17, 50);
     color: white;
}
.con-h5{
    font-weight: bolder;
     text-align: center; 
    margin-top: 1rem;
    color: rgb(64, 64, 252);
}
.con-card{
    margin-top: 3rem;
    margin-bottom: 2rem;
    border: none;
}
.name,.email,.subject,.msg{
    font-weight: 500;
    /* background-color: #f8f8fa; */
}
.con-btn{
    margin-bottom: 2rem;
    display: flex;
    border-radius: 8px;
    font-weight: 500;
}
.con-icon1{
    display: flex;
    justify-content: center;
}
.con-p1{
    justify-content: center;
    text-align: center;
}

.con-icon{
    color: #FF914D;
    display: flex;
    justify-content: center;
}

.con-col1{
    margin-bottom: 2rem;
    box-shadow: 2px 2px 2px 2px rgb(223, 220, 220);
}
.con-icon2 {
    
    display: flex;
    justify-content: center;
}
.call2{
    margin-left: rem;
}

.headerc{
    background-color: #FF914D;
    border-bottom-left-radius: 300px;
    border-bottom-right-radius: 300px;
    height: 60px;
    text-align: center;
}

/* Remove underline from all links within breadcrumb */
.custom-breadcrumb .breadcrumb-item a{
    color: #393635; /* Change the color to your desired color */
    font-size: 16px; /* Change the font size */
    font-weight: bold; /* Change the font weight */
    text-decoration: none; /* Remove the underline */
}

.custom-breadcrumb .breadcrumb-item a:hover {
    color: #ffffff;/* Change the color on hover */
    text-decoration: none; /* Remove the underline on hover */
}

.custom-breadcrumb .breadcrumb-item a.active {
    color: #ffffff; /* Change the color of the active item */
    font-style: italic; /* Change the style of the active item */
    text-decoration: none; /* Remove the underline from the active item */
}



.logotextfirst {
    color:   #FF914D;
   
  }
.logotextsecond {
    color:   #393635;
   
  }
  .textdecoration {
  
    text-decoration: none;
   }